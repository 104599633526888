import { uniqueId } from "lodash-es";
import { Form } from "react-bootstrap";
import { FieldRenderProps } from "react-final-form";
import { errorState } from "src/util/forms";
import { FieldGroup, FieldHelp } from "./FieldWrapper";
import { RequiredMark } from "./RequiredMark";

type Props = FieldRenderProps<string, any> & {
  required?: boolean;
  type: "checkbox" | "radio" | "switch";
};

export const CheckField: React.FC<Props> = ({
  input,
  meta,
  label,
  className,
  helpText,
  required = false,
  ...rest
}) => {
  const { hasError, showError } = errorState(meta);

  return (
    <FieldGroup className={className}>
      <Form.Check
        id={input.id || input.name || uniqueId("check")}
        label={
          <>
            {label}
            {required && <RequiredMark />}
            {helpText && (
              <>
                <FieldHelp className="d-block" helpText={helpText} />
              </>
            )}
          </>
        }
        {...input}
        {...rest}
        checked={input.checked}
        feedback={hasError ? meta.error : undefined}
        feedbackType={showError ? "invalid" : undefined}
        isInvalid={showError}
      />
    </FieldGroup>
  );
};
