import { format } from "date-fns";
import { formatAddress } from "localized-address-format";
import { compact, truncate } from "lodash-es";
import { Address, DocumentExtension } from "src/types";

export const documentFilename = (
  title: string,
  extension: DocumentExtension = "pdf",
): string => {
  const date = format(new Date(), "yyyy-MM-dd");
  return `${date} - ${title}.${extension}`;
};

export const inlineAddress = (address: Address): string =>
  formattedAddress(address).join(", ");

export const formattedAddress = (address: Address): string[] =>
  formatAddress({
    postalCountry: address.country ?? undefined,
    administrativeArea: address.state ?? undefined,
    locality: address.city ?? undefined,
    postalCode: address.zip ?? undefined,
    addressLines: compact([address.street, address.street2]),
  });

export const fieldToken = (label: string, trunc: boolean = false) => {
  const token = `[[${label.toUpperCase()}]]`;
  return trunc ? truncate(token, { length: 24 }) : token;
};

export const lexicalJoin = (
  list: string[],
  style: Intl.ListFormatStyle = "long",
  or: boolean = false,
) => {
  const formatter = new Intl.ListFormat(navigator?.language ?? "en", {
    style,
    type: or ? "disjunction" : "conjunction",
  });

  return formatter.format(compact(list));
};

export const wrapClean = (content: string, start: string, end: string) => {
  const cleanContent = content.replaceAll(start, "").replaceAll(end, "");

  return `${start}${cleanContent}${end}`;
};
