import { useQueryClient } from "@tanstack/react-query";
import { CellContext } from "@tanstack/react-table";
import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { ArchivePacketButton } from "src/components/buttons/ArchivePacketButton";
import { CopyDocumentIconButton } from "src/components/buttons/CopyDocumentIconButton";
import { DeletePacketIconButton } from "src/components/buttons/DeletePacketIconButton";
import { DownloadDocumentIconButton } from "src/components/buttons/DownloadDocumentIconButton";
import { PacketSummary } from "src/types";
import { packetsKeys } from "src/util/api/packetsApi";
import { isThinPacket } from "src/util/models/packets";
import { PacketDocRow } from "./DocumentTable";

export const RowActions: React.FC<CellContext<PacketDocRow, any>> = ({
  row,
}) => {
  if (isThinPacket(row.original)) {
    return <DocRowActions packet={row.original} />;
  } else {
    return <PacketRowActions packet={row.original} />;
  }
};

const PacketRowActions: React.FC<{ packet: PacketSummary }> = ({ packet }) => {
  const queryClient = useQueryClient();

  const invalidateAllPackets = () =>
    queryClient.invalidateQueries({ queryKey: packetsKeys().all });

  return (
    <ButtonGroup>
      <ArchivePacketButton
        packet={packet}
        size="sm"
        variant="secondary"
        title="Archive Packet"
        onArchive={invalidateAllPackets}
        onUnarchive={invalidateAllPackets}
        iconOnly
      />

      {packet.canDestroy && (
        <DeletePacketIconButton
          packet={packet}
          size="sm"
          title="Delete"
          variant="secondary"
          iconColor="danger"
        />
      )}
    </ButtonGroup>
  );
};

const DocRowActions: React.FC<{ packet: PacketSummary }> = ({ packet }) => {
  const queryClient = useQueryClient();
  const invalidateAllPackets = () =>
    queryClient.invalidateQueries({ queryKey: packetsKeys().all });

  const doc = packet.documents[0];

  return (
    <ButtonGroup>
      <DownloadDocumentIconButton
        document={doc}
        size="sm"
        title="Download"
        variant="secondary"
      />

      <CopyDocumentIconButton
        onCopy={invalidateAllPackets}
        document={doc}
        size="sm"
        title="Copy"
        variant="secondary"
      />

      <ArchivePacketButton
        packet={packet}
        iconOnly
        size="sm"
        variant="secondary"
        onArchive={invalidateAllPackets}
        onUnarchive={invalidateAllPackets}
      />

      {packet.canDestroy && (
        <DeletePacketIconButton
          onCopy={invalidateAllPackets}
          packet={packet}
          size="sm"
          title="Delete"
          variant="secondary"
          iconColor="danger"
        />
      )}
    </ButtonGroup>
  );
};
