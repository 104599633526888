import React from "react";
import { useAuth, useAuthGate } from "src/state/AuthContext";

export const withPageGate = <T extends object>(
  Component: React.ComponentType,
): React.FC<T> => {
  const GatedComponent = (props: T) => (
    <PageGate>
      <Component {...props} />
    </PageGate>
  );

  GatedComponent.displayName = `Gated${Component.displayName}`;

  return GatedComponent;
};

export const PageGate: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const authGateChecked = useAuthGate();

  if (!isAuthenticated || !authGateChecked) {
    return null;
  }

  return <>{children}</>;
};
