import { Icon, Loading } from "@ream/ui";
import { XIcon } from "lucide-react";
import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import { Tag, TagId } from "src/types";

type Props = {
  onRemove?: (tagId: TagId) => Promise<void>;
  tag: Tag;
} & React.ComponentProps<typeof Badge>;

export const TagBadge: React.FC<Props> = ({ onRemove, tag, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleRemove = async (tagId: TagId) => {
    if (onRemove) {
      setLoading(true);
      await onRemove(tagId);
      setLoading(false);
    }
  };

  return (
    <Badge
      {...rest}
      bg={tag.colorHex ? "none" : "secondary"}
      style={{
        backgroundColor: tag.colorHex,
        border: `1px solid ${tag.colorHex}`,
      }}
    >
      <div className="d-flex flex-row align-items-center">
        {tag.name}

        {onRemove && (
          <button
            className="bg-transparent opacity-50 border-0 p-0 m-0 ms-2 d-inline-flex align-items-center justify-content-center text-white"
            onClick={() => handleRemove(tag.publicUid)}
            disabled={loading}
          >
            {loading ? <Loading size="xs" /> : <Icon icon={XIcon} />}
          </button>
        )}
      </div>
    </Badge>
  );
};
