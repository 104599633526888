import { DocumentSummary, Packet, PacketSummary, TagId } from "src/types";
import { useDocumentTagActions } from "src/util/api/documentsApi";
import { TagBadge } from "../../TagBadge";

const DocumentTags: React.FC<{
  doc: DocumentSummary;
  onRemove?: (removedTagId: TagId) => void;
}> = ({ doc, onRemove }) => {
  const { remove: removeTag } = useDocumentTagActions(doc);

  const handleRemove = async (tagId: TagId) => {
    await removeTag.mutateAsync(
      { id: doc.publicUid, tagId },
      {
        onSuccess: () => {
          onRemove?.(tagId);
        },
      },
    );
  };

  return (
    <>
      {doc.tags.map((t) => {
        return (
          <TagBadge
            className="me-1"
            key={t.publicUid}
            tag={t}
            onRemove={handleRemove}
          />
        );
      })}
    </>
  );
};

export const TagsCell: React.FC<{ packet: Packet | PacketSummary }> = ({
  packet,
}) => {
  return (
    <div style={{ width: 200, overflowX: "auto", whiteSpace: "nowrap" }}>
      {packet.documents.map((d) => (
        <DocumentTags key={d.publicUid} doc={d} />
      ))}
    </div>
  );
};
