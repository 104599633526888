import { CopyIcon } from "lucide-react";
import React from "react";
import { AppRoutes } from "src/util/appRoutes";
import { useRedirect } from "src/util/redirect";
import { CopyDocumentButton } from "./CopyDocumentButton";

type Props = React.ComponentProps<typeof CopyDocumentButton>;

export const CopyDocumentIconButton: React.FC<Props> = (props) => {
  const r = useRedirect();
  return (
    <CopyDocumentButton
      variant="secondary"
      StartIcon={CopyIcon}
      iconOnly
      {...props}
      onCopy={(doc) => {
        r(AppRoutes.document({ id: doc.publicUid }));
      }}
    />
  );
};
