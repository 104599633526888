import { SortingState } from "@tanstack/react-table";
import {
  parseAsBoolean,
  parseAsInteger,
  parseAsJson,
  useQueryState,
} from "nuqs";

export const useTableQueryParams = (
  paramPrefix: string = "",
  defaultSort?: SortingState,
) => {
  const [page, setPage] = useQueryState(
    `${paramPrefix}page`,
    parseAsInteger.withOptions({ clearOnDefault: true }).withDefault(1),
  );
  const [sort, setSort] = useQueryState(
    `${paramPrefix}sort`,
    parseAsJson<SortingState>()
      .withOptions({ history: "replace", clearOnDefault: true })
      .withDefault(defaultSort ?? []),
  );

  const [q, setQ] = useQueryState(`${paramPrefix}q`, {
    history: "replace",
    clearOnDefault: true,
  });

  return { page, q: q ?? undefined, sort, setQ, setPage, setSort };
};

export const useQueryBool = (name: string, defaultValue: boolean = false) =>
  useQueryState(
    name,
    parseAsBoolean
      .withDefault(defaultValue)
      .withOptions({ clearOnDefault: true }),
  );
