import clsx from "clsx";

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  backLink?: React.ReactNode;
  id?: string;
};

export const PageHeader: React.FC<Props> = ({
  title,
  subtitle,
  className,
  children,
  backLink,
  id,
}) => {
  return (
    <>
      {backLink && <div className="d-block mb-3">{backLink}</div>}
      <header
        id={id}
        className={clsx(
          className,
          "page_header mb-3 mb-md-4 d-flex flex-row align-items-start justify-content-between",
        )}
      >
        <div>
          <h1 className="page_header__title h3">{title}</h1>
          {subtitle && (
            <h2 className="page_header__subtitle h5 text-muted">{subtitle}</h2>
          )}
        </div>

        {Boolean(children) && (
          <div className="ms-auto page_header__actions">{children}</div>
        )}
      </header>
    </>
  );
};
