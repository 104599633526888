import {
  ColumnDef,
  ColumnHelper,
  createColumnHelper,
} from "@tanstack/react-table";

import { useMemo } from "react";

type ColumnFactory<T, P extends any = any> = (
  columnHelper: ColumnHelper<T>,
  args?: any,
) => ColumnDef<T, P>[];

type UseColumnsHook<T> = (args?: any) => ColumnDef<T>[];

export const makeColumns = <T>(fn: ColumnFactory<T>): UseColumnsHook<T> => {
  const columnHelper = createColumnHelper<T>();

  return (p?: any) => useMemo(() => fn(columnHelper, p), [p]);
};
