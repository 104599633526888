import clsx from "clsx";
import React from "react";
import { CloseButton, Popover, PopoverProps } from "react-bootstrap";

type DialogPopoverProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  onHide?: () => void;
  size?: "sm" | "md" | "lg" | "xl" | "xxl";
} & PopoverProps;

export const DialogPopover: React.FC<DialogPopoverProps> = React.forwardRef<
  HTMLDivElement,
  DialogPopoverProps
>(
  (
    { children, footer, title, onHide, size = "sm", className, ...rest },
    ref,
  ) => {
    return (
      <Popover
        className={clsx(`shadow-lg minw-container-${size}`, className)}
        {...rest}
        ref={ref}
        arrowProps={{ style: { display: "none" } }}
      >
        {(title || onHide) && (
          <Popover.Header className="d-flex flex-row align-items-center">
            {title && <h5 className="card-title">{title}</h5>}

            {onHide && <CloseButton className="ms-auto" onClick={onHide} />}
          </Popover.Header>
        )}

        <Popover.Body>{children}</Popover.Body>

        {footer && <footer className="popover-footer">{footer}</footer>}
      </Popover>
    );
  },
);

DialogPopover.displayName = "DialogPopover";
