import { Icon } from "@ream/ui";
import clsx from "clsx";
import { castArray, flatMap, reject, uniqBy } from "lodash-es";
import { LucideIcon } from "lucide-react";
import React, { useEffect, useMemo } from "react";
import { Stack } from "react-bootstrap";
import Dropzone, { Accept } from "react-dropzone";
import { FileValue } from "src/types";
import { cheapFileHash, prepareMultiFileData } from "src/util/file";
import { errorState } from "src/util/forms";
import { useImmer } from "use-immer";
import { DropzoneContainer } from "./DropzoneContainer";
import { FieldGroup, FieldLabel } from "./FieldWrapper";
import { FileList } from "./FileList";

type Props = {
  input: any;
  label: string;
  meta: any;
  accept: Accept;
  hint: string;
  className?: string;
  hideInputWhenValue?: boolean;
  required?: boolean;
  block?: boolean;
  StartIcon?: LucideIcon;
  disabled?: boolean;
  PreviewComponent?: React.ComponentType<{
    files: FileValue[];
    onRemove: (_file: FileValue) => void;
  }>;
};

export const DropzoneField: React.FC<Props> = ({
  input,
  label,
  meta,
  hint = "Drop Files Here to Add",
  accept = {
    "image/*": [],
    "application/pdf": [".pdf"],
  },
  className,
  block,
  StartIcon,
  PreviewComponent = FileList,
  hideInputWhenValue = false,
  required = false,
  disabled,
}) => {
  const { multiple, id, autoFocus, readOnly, onChange } = input;
  const [files, setFiles] = useImmer<FileValue[]>(
    castArray(input.value).filter((x) => x) || [],
  );

  const handleDrop = async (droppedFiles: File[]) => {
    const processedFiles = await prepareMultiFileData(droppedFiles);

    if (multiple) {
      const newFiles = uniqBy([...files, ...processedFiles], cheapFileHash);
      setFiles(newFiles);
      onChange(newFiles);
    } else {
      setFiles(processedFiles);
      onChange(processedFiles[0]);
    }
  };

  const handleRemoveFile = (attachment: FileValue) => {
    const newFiles = reject(files, (f) => f.data === attachment.data);

    setFiles(newFiles);
    onChange(newFiles.length < 1 ? null : newFiles);
  };

  const { showError } = errorState(meta);

  const shouldHide = Boolean(
    hideInputWhenValue && files.length > 0 && !multiple,
  );

  const allowedFileTypes = useMemo(() => {
    return accept ? flatMap(Object.values(accept)).join(", ") : null;
  }, [accept]);

  useEffect(() => {
    setFiles(castArray(input.value).filter((x) => x) || []);
  }, [input.value, setFiles]);

  return (
    <FieldGroup className={clsx(className, { "dropzone--block": block })}>
      <FieldLabel label={label} required={required} />

      {!shouldHide && (
        <Dropzone accept={accept} onDrop={handleDrop} disabled={disabled}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()}>
                <DropzoneContainer
                  isDragActive={isDragActive}
                  disabled={disabled}
                  className={clsx(showError && "dropzone--reject")}
                >
                  <input
                    {...getInputProps({
                      multiple,
                      id,
                      disabled,
                      autoFocus,
                      readOnly,
                    })}
                  />

                  <Stack className="align-items-center" gap={1}>
                    {StartIcon && (
                      <Icon
                        icon={StartIcon}
                        className="text-subtle"
                        size="lg"
                      />
                    )}

                    <div className="text-center">
                      {hint && <p>{hint}</p>}
                      {allowedFileTypes && (
                        <p className="small">Accepts: {allowedFileTypes}</p>
                      )}
                      {showError && <p className="mt-2 small">{meta.error}</p>}
                    </div>
                  </Stack>
                </DropzoneContainer>
              </div>
            );
          }}
        </Dropzone>
      )}

      {PreviewComponent && files.length > 0 && (
        <>
          <PreviewComponent files={files} onRemove={handleRemoveFile} />
          {showError && <p className="mt-2 small text-danger">{meta.error}</p>}
        </>
      )}
    </FieldGroup>
  );
};
