import { isEmail } from "@ream/utils";
import {
  isBoolean,
  isEmpty,
  isNaN,
  isNil,
  isNumber,
  isString,
  reduce,
} from "lodash-es";

export type ValidatorFn = (
  _value: string | number | boolean | null,
) => string | undefined;

export type ValidatorGenerator = (...args: any[]) => ValidatorFn;

export const composeValidators =
  (...validators: ValidatorFn[]): ValidatorFn =>
  (value: string | number | boolean | null): string | undefined =>
    reduce<ValidatorFn[], string | undefined>(
      validators,
      (error, validator: any) => error || validator(value),
      undefined,
    );

export const isBlank = (v: any) =>
  (isEmpty(v) && !isNumber(v) && !isBoolean(v)) || isNaN(v) || isNil(v);

export const required: ValidatorGenerator =
  (msg: string = "Required"): ValidatorFn =>
  (value: string | number | boolean | null) =>
    isBlank(value) ? msg : undefined;

export const requireEmail: ValidatorGenerator =
  (msg: string = "Must be an email"): ValidatorFn =>
  (value) =>
    isEmail(value) ? undefined : msg;

export const minLength: ValidatorGenerator =
  (length: number, msg: string = "Minimum length required"): ValidatorFn =>
  (value) => {
    if (isString(value)) {
      return value.length < length ? msg : undefined;
    } else if (isNumber(value)) {
      return value < length ? msg : undefined;
    }

    return undefined;
  };

export const maxLength: ValidatorGenerator =
  (length: number, msg: string = "Maximum length required"): ValidatorFn =>
  (value) => {
    if (isString(value)) {
      return value.length > length ? msg : undefined;
    } else if (isNumber(value)) {
      return value > length ? msg : undefined;
    }
  };
