import { Button, useConfirmationDialog } from "@ream/ui";
import { ArchiveIcon, ArrowUpCircleIcon } from "lucide-react";
import React from "react";
import { toast } from "react-toastify";
import { Packet, PacketId, PacketSummary } from "src/types";
import { useArchivePacket, useUnarchivePacket } from "src/util/api/packetsApi";
import { isThinPacket } from "src/util/models/packets";

type Props = {
  packet: Packet | PacketSummary;
  onArchive?: (packetId: PacketId) => void;
  onUnarchive?: (packetId: PacketId) => void;
} & React.ComponentProps<typeof Button>;

export const ArchivePacketButton: React.FC<Props> = ({
  packet,
  onArchive,
  onUnarchive,
  children,
  ...rest
}) => {
  const isThin = isThinPacket(packet);
  const archive = useArchivePacket(packet.publicUid);
  const unarchive = useUnarchivePacket(packet.publicUid);

  const handleArchive = async () => {
    await archive.mutateAsync(
      { id: packet.publicUid },
      {
        onSuccess: () => {
          if (onArchive) {
            onArchive(packet.publicUid);
          }
        },
      },
    );
  };

  const handleUnarchive = async () => {
    await unarchive.mutateAsync(
      { id: packet.publicUid },
      {
        onSuccess: () => {
          if (onUnarchive) {
            onUnarchive(packet.publicUid);
          }
        },
      },
    );
  };

  const [archiveDialog, openArchiveDialog] = useConfirmationDialog({
    title: `Archive ${document.title}`,
    message: `Are you sure you want to archive this ${
      isThin ? "document" : "packet"
    }?`,
    onConfirm: async () => {
      await toast.promise(handleArchive(), {
        pending: `Archiving ${isThin ? "Document" : "Packet"}...`,
        success: `${isThin ? "Document" : "Packet"} Archived`,
        error: {
          render: () =>
            `Error while archiving ${isThin ? "document" : "packet"}.`,
        },
      });
    },
  });

  const [unarchiveDialog, openUnarchiveDialog] = useConfirmationDialog({
    title: `Unarchive ${document.title}`,
    message: `Are you sure you want to unarchive this ${
      isThin ? "document" : "packet"
    }?`,
    onConfirm: async () => {
      await toast.promise(handleUnarchive(), {
        pending: `Unarchiving ${isThin ? "Document" : "Packet"}...`,
        success: `${isThin ? "Document" : "Packet"} unarchived`,
        error: {
          render: () =>
            `Error while unarchiving ${isThin ? "document" : "packet"}.`,
        },
      });
    },
  });

  const defaultLabel = packet.archived ? "Unarchive" : "Archive";
  const defaultIcon = packet.archived ? ArrowUpCircleIcon : ArchiveIcon;

  return (
    <>
      <Button
        loading={archive.isPending || unarchive.isPending}
        onClick={() =>
          packet.archived ? openUnarchiveDialog() : openArchiveDialog()
        }
        StartIcon={defaultIcon}
        {...rest}
      >
        {children ? children : defaultLabel}
      </Button>

      {packet.archived ? unarchiveDialog : archiveDialog}
    </>
  );
};
