import clsx from "clsx";
import React from "react";

type Props = {
  isDragAccept?: boolean;
  isDragActive?: boolean;
  isDragReject?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const DropzoneContainer: React.FC<Props> = ({
  isDragAccept,
  isDragActive,
  isDragReject,
  className,
  children,
  ...props
}) => {
  const classes = clsx(className, "dropzone", {
    "dropzone--active": isDragActive,
    "dropzone--reject": isDragReject,
    "dropzone--accept": isDragAccept,
    "dropzone--disabled": props.disabled,
  });

  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
