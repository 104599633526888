import React from "react";
import { FileValue } from "src/types";
import { humanFileSize } from "src/util/file";

export const FileDetails: React.FC<{ file: FileValue }> = ({ file: f }) => {
  return (
    <div>
      <span className="font-monospace">{f.filename}</span>

      <br />

      {f.size && (
        <small className="font-monospace text-muted">
          {humanFileSize(f.size)}
        </small>
      )}
    </div>
  );
};
