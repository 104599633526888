import { CellContext } from "@tanstack/react-table";
import Link from "next/link";
import { AppRoutes } from "src/util/appRoutes";
import { lexicalJoin } from "src/util/formatters";
import { isThinPacket } from "src/util/models/packets";
import { PacketDocRow } from "./DocumentTable";

export const TitleCell: React.FC<CellContext<PacketDocRow, string>> = ({
  getValue,
  row,
}) => {
  const firstDocument = row.original.documents?.[0];
  const documentLink = AppRoutes.document({ id: firstDocument?.publicUid });

  return (
    <>
      {firstDocument ? (
        <Link data-test="document-cell-link" href={documentLink}>
          {getValue()}
        </Link>
      ) : (
        <span>{getValue() ?? row.original.title ?? "(Untitled)"}</span>
      )}

      <br />

      <small className="text-muted">
        {isThinPacket(row.original) ? (
          row.original.template && (
            <>From template: {row.original.template?.title}</>
          )
        ) : (
          <>
            Contains:{" "}
            {lexicalJoin(
              row.original.documents.map((d) => d.title),
              "short",
            )}
          </>
        )}
      </small>
    </>
  );
};
