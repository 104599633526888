import hash from "object-hash";
import React from "react";
import { CloseButton, ListGroup } from "react-bootstrap";
import { FilePreviewComponent } from "src/types";
import { FileDetails } from "./FileDetails";

export const FileList: React.FC<FilePreviewComponent> = ({
  files,
  onRemove,
}) => {
  return (
    <ListGroup>
      {files
        .filter((x) => Boolean(x))
        .map((f, idx) => {
          const key = `${hash(f)}-${idx}`;

          return (
            <ListGroup.Item
              key={key}
              className="d-flex flex-row align-items-center justify-content-between"
            >
              <FileDetails file={f} />

              {onRemove && <CloseButton onClick={() => onRemove(f)} />}
            </ListGroup.Item>
          );
        })}
    </ListGroup>
  );
};
