import { BaseOverlay, BaseOverlayProps } from "@ream/ui";
import { FileIcon } from "lucide-react";
import React from "react";
import { Stack } from "react-bootstrap";
import { Field, Form, FormSpy } from "react-final-form";
import { DialogPopover } from "src/components/DialogPopover";
import { FormSubmitButton } from "src/components/form/FormSubmitButton";
import { TextField } from "src/components/form/TextField";
import {
  Doc,
  DocumentContentFile,
  FileValue,
  Packet,
  PacketReference,
  PacketSummary,
} from "src/types";
import { useCreateDocument } from "src/util/api/documentsApi";
import { useFeatureEnabled } from "src/util/api/features";
import Feature from "src/util/feature";
import { cheapFileHash } from "src/util/file";
import { required } from "src/util/validators";
import { CheckField } from "../form/CheckField";
import { DropzoneField } from "../form/DropzoneField";

type CreateDocumentPopoverProps = {
  trigger: React.ReactElement;
  template: boolean;
  packet?: Packet | PacketSummary | PacketReference;
  onCreate: (doc: Doc) => Promise<void>;
  overlayProps?: Partial<Omit<BaseOverlayProps, "children">>;
};

export const CreateDocumentPopover: React.FC<CreateDocumentPopoverProps> = ({
  template,
  trigger,
  onCreate,
  packet,
  overlayProps,
}) => {
  const create = useCreateDocument();
  const fbdEnabled = useFeatureEnabled(Feature.FILE_BACKED_DOCUMENTS);

  const handleSubmit = async (data: {
    title: string;
    showFileInput: boolean;
    documentFileContent?: FileValue;
  }) => {
    const result = await create.mutateAsync({
      data: {
        title: data.title,
        documentFileContent: data.documentFileContent as DocumentContentFile,
        packetId: packet?.publicUid,
        isTemplate: template,
      },
    });

    await onCreate(result.document);
  };

  return (
    <BaseOverlay placement="bottom-end" {...overlayProps} trigger={trigger}>
      {({ handleClose }) => (
        <Form
          onSubmit={handleSubmit}
          keepDirtyOnReinitialize
          render={({ handleSubmit, values, form }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogPopover
                  onHide={handleClose}
                  title="Create Document"
                  footer={
                    <Stack direction="horizontal">
                      <FormSubmitButton
                        data-test="popover-create-document-button"
                        className="ms-auto"
                      >
                        {values.documentFileContent
                          ? "Create & View"
                          : "Create & Edit"}
                      </FormSubmitButton>
                    </Stack>
                  }
                >
                  <Stack gap={3}>
                    <Field
                      data-test="popover-create-document-title"
                      label="Title"
                      placeholder="My Document"
                      name="title"
                      validate={required("A title is required")}
                      suppressErrorUntilSubmitted
                      component={TextField}
                      autoFocus
                    />

                    <FormSpy
                      subscription={{ values: true }}
                      onChange={({ values: newValues }) => {
                        // If we don't have a title, and we have a file
                        // and the file is not the same file we had last time
                        // then set the title to the filename of the new file
                        if (!newValues.title && newValues.documentFileContent) {
                          const fileChanged =
                            !values.documentFileContent ||
                            cheapFileHash(values.documentFileContent) !==
                              cheapFileHash(newValues.documentFileContent);

                          if (fileChanged) {
                            form.change(
                              "title",
                              newValues.documentFileContent.filename,
                            );
                          }
                        }
                      }}
                    />

                    {fbdEnabled && (
                      <>
                        <Field
                          name="showFileInput"
                          label="Upload existing file"
                          helpText="Choose this if you have a PDF that you’d like to upload, instead of editing the file in Ream"
                          component={CheckField}
                          type="checkbox"
                        />

                        {values.showFileInput && (
                          <Field
                            label="File"
                            accept={{ "application/pdf": [".pdf"] }}
                            className="w-100"
                            multiple={false}
                            hideInputWhenValue
                            hint="Choose a file"
                            name="documentFileContent"
                            StartIcon={FileIcon}
                            component={DropzoneField}
                          />
                        )}
                      </>
                    )}
                  </Stack>
                </DialogPopover>
              </form>
            );
          }}
        />
      )}
    </BaseOverlay>
  );
};
