import { Alert, IconCircle, formatDateTime } from "@ream/ui";
import React, { useMemo } from "react";
import { Form } from "react-bootstrap";
import { PaginatedTable } from "src/components/table/PaginatedTable";
import { DocSummary, PacketSummary } from "src/types";
import { extractErrorMessage } from "src/util/api/apiError";
import { usePackets } from "src/util/api/packetsApi";
import { lexicalJoin } from "src/util/formatters";
import { makeColumns } from "src/util/hooks/makeColumns";
import {
  useQueryBool,
  useTableQueryParams,
} from "src/util/hooks/useTableQueryParams";
import { packetIconAndColor } from "src/util/models/packets";
import { TagsCell } from "../cells/TagsCell";
import { RowActions } from "./RowActions";
import { TitleCell } from "./TitleCell";

const useColumns = makeColumns<PacketDocRow>((helper) => {
  return [
    helper.display({
      id: "icon",
      enableHiding: false,
      maxSize: 64,
      cell: ({ row }) => {
        const [icon, color] = packetIconAndColor(row.original);
        return <IconCircle icon={icon} color={color} flat rounded="rounded" />;
      },
    }),
    helper.accessor("title", {
      size: 280,
      header: "Title",
      enableHiding: false,
      cell: TitleCell,
    }),
    helper.accessor("owner.fullName", {
      id: "owner",
      header: "Owner",
      enableSorting: false,
    }),
    helper.accessor(
      (row) =>
        lexicalJoin(
          row.documents.flatMap((d) => d.companies?.map((c) => c.name)),
        ),
      {
        header: "Companies",
        enableSorting: false,
      },
    ),
    helper.display({
      header: "Tags",
      id: "tags",
      enableSorting: false,
      cell: (props) => <TagsCell packet={props.row.original} />,
    }),

    helper.accessor((row) => formatDateTime(row.updatedAt), {
      id: "updatedAt",
      header: "Last Saved",
    }),
    helper.accessor(
      (row) => (row.editedAt ? formatDateTime(row.editedAt) : null),
      {
        id: "editedAt",
        header: "Last Edited",
      },
    ),
    helper.display({
      id: "actions",
      enableHiding: false,
      cell: RowActions,
    }),
  ];
});

export type PacketDocRow = PacketSummary & { subRows: DocSummary[] };

export const DocumentTable: React.FC<{ templates?: boolean }> = ({
  templates = false,
}) => {
  const [archived, setArchived] = useQueryBool("archived");
  const [mine, setMine] = useQueryBool("mine");
  const [hideCampaign, setHideCampaign] = useQueryBool("hide_campaign");

  const { page, q, sort, setQ, setPage, setSort } = useTableQueryParams("", [
    { id: "updatedAt", desc: true },
  ]);

  const defaultData = { packets: [], meta: {} };
  const columns = useColumns();

  const { data: { packets, meta } = defaultData, query: packetsQuery } =
    usePackets({
      page,
      q,
      mine,
      archived,
      templates,
      sort,
      hideCampaign,
    });

  const transformed: PacketDocRow[] = useMemo(() => {
    return packets.flatMap((p) => {
      const result: PacketDocRow = { ...p, subRows: [] };

      if (p.documents.length > 0) {
        result.subRows = p.documents;
      }

      return result;
    });
  }, [packets]);

  return (
    <>
      {packetsQuery.isError && (
        <Alert variant="danger" className="mb-3">
          {extractErrorMessage(packetsQuery.error)}
        </Alert>
      )}

      <PaginatedTable
        emptyLabel="No Documents"
        data={transformed}
        meta={meta}
        columns={columns}
        isLoading={packetsQuery.isLoading}
        q={q}
        sort={sort}
        page={page}
        onSearch={setQ}
        onPage={setPage}
        onSort={setSort}
        getRowId={(row) => row.publicUid}
        Filters={
          <>
            <Form.Check
              type="checkbox"
              id="mine-switch"
              checked={mine}
              onChange={(e) => setMine(e.target.checked)}
              label="Only mine"
              className="ms-3"
            />

            <Form.Check
              type="checkbox"
              id="archived-switch"
              checked={archived}
              onChange={(e) => setArchived(e.target.checked)}
              label="Archived"
              className="ms-3"
            />

            {!templates && (
              <Form.Check
                type="checkbox"
                id="hide-campaign-switch"
                checked={hideCampaign}
                onChange={(e) => setHideCampaign(e.target.checked)}
                label="Hide Campaign"
                className="ms-3"
              />
            )}
          </>
        }
      />
    </>
  );
};
