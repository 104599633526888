import { Button, useConfirmationDialog } from "@ream/ui";
import React from "react";
import { toast } from "react-toastify";
import { PacketSummary } from "src/types";
import { useDestroyPacket } from "src/util/api/packetsApi";

type Props = {
  packet: PacketSummary;
  onDelete?: (_documentId: string) => void;
} & React.ComponentProps<typeof Button>;

export const DeletePacketButton: React.FC<Props> = ({
  packet,
  onDelete,
  children,
  ...rest
}) => {
  const destroy = useDestroyPacket();

  const handleDelete = async () => {
    await destroy.mutateAsync(
      { id: packet.publicUid },
      {
        onSuccess: () => {
          if (onDelete) {
            onDelete(packet.publicUid);
          }
        },
      },
    );
  };

  const [deleteDialog, openDeleteDialog] = useConfirmationDialog({
    title: `Delete ${packet.title}`,
    message: "Are you sure you want to delete this document?",
    onConfirm: async () => {
      await toast.promise(handleDelete(), {
        pending: "Deleting Document...",
        success: "Document Deleted",
        error: {
          render: () => "Error while deleting document.",
        },
      });
    },
  });

  return (
    <>
      <Button
        loading={destroy.isPending}
        onClick={() => openDeleteDialog()}
        {...rest}
      >
        {children ? children : "Delete"}
      </Button>

      {deleteDialog}
    </>
  );
};
