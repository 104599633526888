import { Button, ButtonProps } from "@ream/ui";
import clsx from "clsx";
import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { DocumentSummary, DocumentVersion } from "src/types";
import { downloadLink } from "src/util/models/documents";

type Props = {
  includeVariants?: boolean;
  document: DocumentSummary | DocumentVersion;
  version?: number;
} & ButtonProps;

export const DownloadDocumentButton: React.FC<Props> = ({
  document,
  version,
  children,
  block,
  includeVariants = false,
  ...rest
}) => {
  const defaultVersion =
    "documentPublicUid" in document ? document.documentVersion : undefined;

  const v = version ?? defaultVersion;

  const id =
    "documentPublicUid" in document
      ? document.documentPublicUid
      : document.publicUid;

  return (
    <Dropdown as={ButtonGroup} className={clsx({ "w-100": block })}>
      <Button
        {...rest}
        data-test="document-button-download"
        href={downloadLink(id, "pdf", v)}
        target="_blank"
      >
        {children ? children : "Download"}
      </Button>

      {includeVariants && (
        <>
          <Dropdown.Toggle split variant={rest.variant} size={rest.size} />

          <Dropdown.Menu align="end">
            <Dropdown.Item
              download
              href={downloadLink(id, "pdf", v)}
              target="_blank"
            >
              Download as PDF
            </Dropdown.Item>
            <Dropdown.Item
              download
              href={downloadLink(id, "docx", v)}
              target="_blank"
            >
              Download as Word Document
            </Dropdown.Item>
          </Dropdown.Menu>
        </>
      )}
    </Dropdown>
  );
};
