import { Button, useConfirmationDialog } from "@ream/ui";
import React from "react";
import { toast } from "react-toastify";
import { DocSummary } from "src/types";
import { useCopyDocument } from "src/util/api/documentsApi";

type Props = {
  document: DocSummary;
  onCopy?: (doc: DocSummary) => void;
} & Omit<React.ComponentProps<typeof Button>, "onCopy">;

export const CopyDocumentButton: React.FC<Props> = ({
  document,
  onCopy,
  children,
  ...rest
}) => {
  const copy = useCopyDocument(document.publicUid);

  const handleCopy = async () => {
    await copy.mutateAsync(void null, {
      onSuccess: (res) => {
        if (onCopy) {
          onCopy(res.document);
        }
      },
    });
  };

  const [copyDialog, openCopyDialog] = useConfirmationDialog({
    title: `Copy ${document.title}`,
    message: "Are you sure you want to copy this document?",
    onConfirm: async () => {
      await toast.promise(handleCopy(), {
        pending: "Copying Document...",
        success: "Document Copied",
        error: {
          render: () => "Error while copying document.",
        },
      });
    },
  });

  return (
    <>
      <Button
        loading={copy.isPending}
        onClick={() => openCopyDialog()}
        {...rest}
      >
        {children ? children : "Copy"}
      </Button>

      {copyDialog}
    </>
  );
};
