import {
  BasePopover,
  Button,
  EmptyCard,
  Icon,
  LoadingCentered,
  Toolbar,
} from "@ream/ui";
import {
  ColumnDef,
  ExpandedState,
  SortingState,
  Updater,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { debounce, startCase } from "lodash-es";
import {
  ArrowUpDownIcon,
  SettingsIcon,
  SortAscIcon,
  SortDescIcon,
} from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";
import { Form, FormCheck, Stack, Table } from "react-bootstrap";
import { ResponseMeta } from "src/types";
import { TablePager } from "./TablePager";

type PaginatedTableProps<T> = {
  data: any[];
  isLoading: boolean;
  columns: ColumnDef<T>[];
  meta: ResponseMeta;
  Filters?: React.ReactNode;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  emptyLabel?: string;
  sort?: SortingState;
  page?: number;
  q?: string;
  onSearch?: (q: string | null) => void;
  onPage?: (p: number) => void;
  onSort?: (s: SortingState) => void;
  getRowId?: (row: T) => string;
};

export const PaginatedTable = <T,>({
  emptyLabel = "No Data",
  containerProps,
  data,
  meta,
  columns,
  isLoading,
  onSearch,
  onPage,
  onSort,
  Filters,
  getRowId,
  sort = [],
  page = 1,
  q,
}: PaginatedTableProps<T>) => {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});

  const [localSearch, setLocalSearch] = useState(q);

  const debouncedSearch = useMemo(
    () => (onSearch ? debounce(onSearch, 150) : undefined),
    [onSearch],
  );

  const handleSearch = useCallback(
    (q: string) => {
      setExpanded({});
      setLocalSearch(q);

      if (debouncedSearch) {
        debouncedSearch(q);
      }
    },
    [debouncedSearch],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setExpanded({});

      if (onPage) {
        onPage(page);
      }
    },
    [onPage],
  );

  const handleSortingChange = useCallback(
    (updaterFn: Updater<SortingState>) => {
      const newSort =
        typeof updaterFn === "function" ? updaterFn(sort) : updaterFn;

      setExpanded({});

      if (onSort) {
        onSort(newSort);
      }
    },
    [sort, onSort],
  );

  const table = useReactTable({
    columns,
    data,
    state: {
      sorting: sort,
      columnVisibility,
      expanded,
    },
    getRowId,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    onSortingChange: handleSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    manualSorting: true,
  });

  const rows = table.getRowModel().rows;

  return (
    <div className="d-flex flex-column gap-3">
      <Toolbar>
        <div className="d-flex align-items-center">
          <Form.Control
            type="search"
            value={localSearch ?? ""}
            placeholder="Search..."
            style={{ width: 300 }}
            onChange={(e) => handleSearch(e.target.value)}
          />

          {Filters}
        </div>

        <Stack direction="horizontal" gap={2}>
          <BasePopover
            placement="left-start"
            title="Toggle Column Visibility"
            trigger={<Button variant="secondary" StartIcon={SettingsIcon} />}
          >
            {() => {
              return (
                <>
                  <FormCheck
                    id="all"
                    label="All Columns"
                    checked={table.getIsAllColumnsVisible()}
                    onChange={table.getToggleAllColumnsVisibilityHandler()}
                  />

                  <hr className="my-2" />

                  {table.getAllLeafColumns().map((column) => {
                    if (column.getCanHide()) {
                      return (
                        <FormCheck
                          key={column.id}
                          label={
                            column.columnDef.header?.toString() ??
                            startCase(column.id)
                          }
                          id={column.id}
                          type="checkbox"
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                        />
                      );
                    }
                  })}
                </>
              );
            }}
          </BasePopover>

          <TablePager
            page={page}
            totalPages={meta.totalPages ?? Infinity}
            onChange={handlePageChange}
            isLoading={isLoading}
          />
        </Stack>
      </Toolbar>

      <div
        {...containerProps}
        className={clsx(containerProps?.className, "flex-fill")}
      >
        <>
          <Table
            size="small"
            hover={!isLoading}
            className="align-middle table-sticky"
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const canSort = header.column.getCanSort();
                      const sortIconClasses =
                        "ms-auto fw-light text-body-tertiary";

                      const isSorted = header.column.getIsSorted();
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            cursor: canSort ? "pointer" : undefined,
                            width: header.getSize(),
                          }}
                          onClick={
                            canSort
                              ? header.column.getToggleSortingHandler()
                              : undefined
                          }
                        >
                          <span className="d-flex flex-row align-items-cente">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}

                            {canSort && (
                              <>
                                {isSorted ? (
                                  isSorted === "desc" ? (
                                    <Icon
                                      className={sortIconClasses}
                                      icon={SortDescIcon}
                                    />
                                  ) : (
                                    <Icon
                                      className={sortIconClasses}
                                      icon={SortAscIcon}
                                    />
                                  )
                                ) : (
                                  <Icon
                                    className={sortIconClasses}
                                    icon={ArrowUpDownIcon}
                                  />
                                )}
                              </>
                            )}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>

            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={999}>
                    <LoadingCentered />
                  </td>
                </tr>
              ) : (
                <>
                  {rows.length > 0 ? (
                    <>
                      {rows.map((row) => {
                        return (
                          <React.Fragment key={row.id}>
                            <tr>
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <td
                                    key={cell.id}
                                    style={{ width: cell.column.getSize() }}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext(),
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={999}>
                        <EmptyCard emptyLabel={emptyLabel} />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </>
      </div>
    </div>
  );
};
